import axios from 'axios';
import { OrderPayload } from './interfaces/order-payload.interface';

export default class SessionOrderService {
  public static async getOrderData(sessionToken: string): Promise<OrderPayload> {
    const { REACT_APP_CHECKOUT_API_URL, NODE_ENV } = process.env;
    if (NODE_ENV !== 'production' && !REACT_APP_CHECKOUT_API_URL) {
      throw new Error('Missing Environment varaible REACT_APP_CHECKOUT_API_URL');
    }

    const { data } = await axios.get(
      // 'http://localhost:4600/session-token/',
      // 'https://checkout-api.qa.optty.com/checkout-page',
      `${REACT_APP_CHECKOUT_API_URL}/session-token/`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionToken}`,
        },
      }
    ).then((response: any) => {
      return response;
    }).catch((err: any) => {
      if (err.response) {
        if (err.response.data.statusCode === 401) {
          throw new Error('Your session is expired!');
        }
      }
      throw new Error('Something went wrong!');
    });
    return data?.orderData;
  }
}
