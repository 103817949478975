import axios from 'axios';

export default class OrderService {
  public static async getOrderData(
    transactionReference: string | null,
    status: string,
    provider: string,
    sessionToken: string,
    merchantReference: string
  ): Promise<any> {
    const { REACT_APP_CHECKOUT_API_URL, NODE_ENV } = process.env;
    if (NODE_ENV !== 'production' && !REACT_APP_CHECKOUT_API_URL) {
      throw new Error('Missing Environment varaible REACT_APP_CHECKOUT_API_URL');
    }

    let query = `status=${status}&provider=${provider}&merchantReference=${merchantReference}`;
    if (transactionReference) {
      query += `&bnplOrderId=${transactionReference}`;
    }

    const { data } = await axios.get(
      // `http://localhost:4600/order/detail?bnplOrderId=${transactionReference}&status=${status}&provider=${provider}`,
      // 'https://checkout-api.qa.optty.com/checkout-page',
      `${REACT_APP_CHECKOUT_API_URL}/order/detail?${query}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionToken}`,
        },
      }
    ).then((response: any) => {
      return response;
    }).catch((err: any) => {
      if (err.response) {
        if (err.response.data.statusCode === 401) {
          throw new Error('Your session is expired!');
        }
        throw new Error('Something went wrong!');
      }
    });
    return data;
  }

  public static async updateOrderStatus(body: any, sessionToken: string): Promise<any> {
    const { REACT_APP_CHECKOUT_API_URL, NODE_ENV } = process.env;
    if (NODE_ENV !== 'production' && !REACT_APP_CHECKOUT_API_URL) {
      throw new Error('Missing Environment varaible REACT_APP_CHECKOUT_API_URL');
    }

    const { data } = await axios.put(
      // 'http://localhost:4600/order/status',
      // 'https://checkout-api.qa.optty.com/checkout-page',
      `${REACT_APP_CHECKOUT_API_URL}/order/status`,
      body,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionToken}`,
        },
      }
    );
    return data;
  }

  public static async getPaidLinktDetails(
    customHppId: string,
    sessionToken: string
  ): Promise<any> {
    const { REACT_APP_CHECKOUT_API_URL, NODE_ENV } = process.env;
    if (NODE_ENV !== 'production' && !REACT_APP_CHECKOUT_API_URL) {
      throw new Error('Missing Environment varaible REACT_APP_CHECKOUT_API_URL');
    }

    const { data } = await axios.get(
      `${REACT_APP_CHECKOUT_API_URL}/order/get-paid-link-detail/${customHppId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionToken}`,
        },
      }
    );
    return data;
  }
}
