import { CheckoutDataContext } from 'context/merchant-details.context';
import React, {
  useContext, useEffect, useMemo, useState
} from 'react';
import { Bnpl } from 'services/interfaces/bnpl-data.interface';
import parseBnplContent from 'util/contentParser';
import { getCurrencySymbol } from 'util/contentParser/libs';
import CaretDown from '../../assets/images/caretDown.svg';
import CaretUp from '../../assets/images/caretUp.svg';
import bnplItemStyles from './BnplItem.module.css';

interface IBnplItemProps {
  bnpl: Bnpl;
  setDisableBtn?: any;
  disableBtn?: boolean;
  caller?: string;
  origAmount: number;
}

const isCustomerCovered = (bnpl: Bnpl) => bnpl.customerFees === 'custom2';

const isSurcharging = (bnpl: Bnpl) => {
  const isCustomerCovered0 = isCustomerCovered(bnpl);
  const issurcharging = isCustomerCovered0
    || !!(Number(bnpl.customerFeesFlat) || Number(bnpl.customerFeesPercentage));
  return { issurcharging, isCustomerCovered };
};

const computeSurcharge = (
  bnpl: Bnpl,
  initialAmount: number,
  isCustomerCovered: boolean
) => {
  let surcharge: number;

  if (isCustomerCovered) {
    surcharge = (Number(initialAmount) + Number(bnpl.providerFees))
        / (1 - Number(bnpl.providerFeesPercentage) / 100)
      - initialAmount;
  } else {
    surcharge = (Number(initialAmount) * Number(bnpl.customerFeesPercentage)) / 100
      + Number(bnpl.customerFeesFlat);
  }
  const amount = Number(initialAmount) + surcharge;
  return { surcharge, amount };
};

const BnplListItem: React.FC<IBnplItemProps> = ({
  bnpl,
  // disableBtn,
  setDisableBtn = () => {},
  // caller,
  origAmount,
}) => {
  const { selectedBnpl, setSelectedBnpl } = useContext(CheckoutDataContext);
  const isActive = selectedBnpl?.name === bnpl.name;
  const [content, setContent] = useState('');
  const [consented, setConsented] = useState(false);
  const [isSurcharging0, setIsSurcharging0] = useState(false);
  const currencySymbol = useMemo(
    () => getCurrencySymbol((selectedBnpl ?? bnpl).instalment.rate.currency),
    [selectedBnpl]
  );

  useEffect(() => {
    if (selectedBnpl?.name === bnpl.name) {
      parseBnplContent(selectedBnpl).then((content) => {
        setContent(content);
      });
      // if (selectedBnpl && caller === 'optty-direct') setDisableBtn(!disableBtn);
      // const { issurcharging } = isSurcharging(selectedBnpl);
      // setIsSurcharging0(issurcharging);
    }
  }, [selectedBnpl]);

  const handleClick = () => {
    setSelectedBnpl(selectedBnpl?.name === bnpl.name ? undefined : bnpl);
    // if (selectedBnpl) setDisableBtn(isActive || !isSurcharging0 ? true : !disableBtn);

    const { issurcharging } = isSurcharging(bnpl);
    setIsSurcharging0(issurcharging);
    setDisableBtn(isActive || issurcharging);
  };

  const composeSurchargeConsent = () => {
    if (!consented) setDisableBtn(isSurcharging0);

    const isCustomerCovered0 = isCustomerCovered(selectedBnpl!);
    const { surcharge, amount } = computeSurcharge(selectedBnpl!, origAmount, isCustomerCovered0);
    return (
      <div className={bnplItemStyles.surchargeConsentBox}>
        There is an additional surcharge of{' '}
        <strong>
          {currencySymbol}
          {surcharge.toFixed(2)}
        </strong>{' '}
        which brings total amount-payable to{' '}
        <strong>
          {currencySymbol}
          {amount.toFixed(2)}
        </strong>
        . <br />
        <input
          id={`check-${selectedBnpl?.name}`}
          onChange={(e) => {
            const isChecked = e.target.checked;
            setDisableBtn(!isChecked);
            setConsented(isChecked);
          }}
          type="checkbox"
        />
        Please tick the box to accept the customer charges from this provider.
      </div>
    );
  };

  return (
    <div
      className={
        isActive
          ? bnplItemStyles.bnplListItemContainerFull
          : bnplItemStyles.bnplListItemContainer
      }
    >
      <div
        aria-hidden="true"
        className={bnplItemStyles.bnplHeader}
        onClick={handleClick}
      >
        <div className={bnplItemStyles.bnplTitleContainer}>
          <span className={bnplItemStyles.checkbox}>
            <span className={isActive ? bnplItemStyles.checkboxActive : ''} />
          </span>
          <img alt={bnpl.name} className={bnplItemStyles.bnplImage} src={bnpl.imageUrl} />
        </div>

        {isActive ? (
          <img alt="caret up" src={CaretUp} />
        ) : (
          <img alt="caret up" src={CaretDown} />
        )}
      </div>
      {isActive && (
        <div className={bnplItemStyles.bnplDetails}>
          <div className={bnplItemStyles.content}>
            <div
              className={bnplItemStyles.bnplCheckoutConfig}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
          {isSurcharging0 && composeSurchargeConsent()}
        </div>
      )}
    </div>
  );
};

export default BnplListItem;
